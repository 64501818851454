const needHeaderFooter = {
    "/": true,
    "/signin": false,
    "/signup": false,
    "/about": true,
    "/usecases": true,
    "/wallet": true,
    "/services": true,
    "/faq": true,
    "/terms": true
}
export default needHeaderFooter;