const navbarMenus = {
  //leftMenu: [{ title: "Dashboard", page: "/dashboard" }],
  rightMenu: [
    { title: "Technology", page: "/" },
    { title: "Use Cases", page: "/usecases" },
    { title: "Wallet", page: "/wallet" },
    { title: "Register", page: "/signup" },
    { title: "Login", page: "/signin" },
    
    /* { title: "About", page: "/about" },
    { title: "Services", page: "/services" },
    { title: "FAQ", page: "/faq" }, */
  ],
};
export default navbarMenus;
