import React, { useState } from "react"
import { useEffect } from "react"
import { Button, Modal } from "react-bootstrap"
export default function NoticePopup(content) {
  const [show, setShow] = useState(true)
  useEffect(() => {
    window.onload = function () {
      setShow(true)
    }
  })

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <>
      <Modal show={show} centered
      size="lg">
        <Modal.Header
          closeButton
          onClick={() => {
            handleClose()
          }}
        >
          <Modal.Title className=" fw-bold">Update Announcement!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
         <strong> It is a great pleasure to announce that we are introducing the various
          features updates in our platform.</strong>
          <ul className="list-wrapper">
            <li>
              Book hotel and flights worldwide including Nepal and India; just
              using XLAB and XLD and various other cryptos with XcelTrip.com
            </li>
            <li>XcelPay Launchpad is on beta launching phase</li>
            <li>XcelDefi Staking in XcelPay Wallet </li>
            <li> DApp is Launching soon on the XcelPay Wallet</li>
            <li>Payment Gateway Launching soon on XcelPay Wallet</li>
            <li>
              XcelPay card , the ultimate crypto card for all kinds of payment.
              New Blockchains like Avalanche (AVA), Fantom (FTM) and Solana
              (SOL) are getting integrated to the XcelPay Wallet.
            </li>
            <li>
              Experience seamless transactions with XcelPay Wallet’s
              marketplace.
            </li>
            <li>Buy gift cards from global brands</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>Stay tuned for the updates!</Modal.Footer>
      </Modal>
    </>
  )
}
